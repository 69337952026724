import { Headline } from "./Headline"
import { Section } from "./Section"

export const Footer = () => {
    return (
        <>
            
            <Section id="kontakt" classes="bg-bg-2 mt-auto">
                <Headline as="h2">Kontakt</Headline>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10 mt-4">
                    <div>
                        <strong>Kosmetikstudio Adriana Fuchs</strong><br/>
                        Louisenstrasse 4<br/>
                        63538 Großkrotzenburg<br/>
                        {/* Tel.: <a className="underline hover:no-underline" href="tel:06186 1619">06186 1619</a><br/> */}
                        Mobil: <a className="underline hover:no-underline" href="tel:0178 4887924">0178 4887924</a><br/>
                        E-Mail: <a className="underline hover:no-underline" href="mailto:adriana.fuchs@yahoo.de">adriana.fuchs@yahoo.de</a>
                    </div>
                    <div>
                        <strong>Öffnungszeiten</strong><br/>
                        <b>Montag bis Freitag:</b><br/>
                        8:00 – 20:00 Uhr<br/>
                        (Termine nur nach Vereinbarung)
                    </div>
                    <div>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.2887911644534!2d8.979432812210305!3d50.08087971378589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd3e00deff3b6d%3A0x9fee430e1e5aaca3!2sLouisenstra%C3%9Fe%204%2C%2063538%20Gro%C3%9Fkrotzenburg!5e0!3m2!1sde!2sde!4v1710843010273!5m2!1sde!2sde"
                            className="w-full h-44"
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Karte anzeigen"
                        />
                    </div>
                    {/* <div>
                        <strong>Termine</strong><br/>
                        Termine nach Vereinbarung<br/>
                        <a href="https://www.facebook.com/Adriana.Fuchs.Kosmetik" target="_blank" rel="noreferrer" className="underline hover:no-underline">Facebook</a>
                        <div className="mt-4">
                            <strong>Anfahrt</strong><br/>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2583.464732376942!2d9.034547815708145!3d50.07266627942495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0c2b8e0e3f1b%3A0x1c8d1e0e6b6b7c4e!2sKosmetikstudio%20Adriana%20Fuchs!5e0!3m2!1sde!2sde!4v1633663151968!5m2!1sde!2sde" width="100%" height="200" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div> */}
                </div>
            </Section>

            <div className="w-full bg-white shadow-lg flex-col relative z-50 px-4 py-6">
                <div className="w-full max-w-screen-xl flex flex-col md:items-center md:justify-between md:flex-row md:mx-auto">
                    <ul className="flex flex-col md:flex-row gap-y-2 md:gap-4">
                        {/* <NaviItem href="/agbs">AGBs</NaviItem> */}
                        <NaviItem href="/datenschutz">Datenschutz</NaviItem>
                        <NaviItem href="/impressum">Impressum</NaviItem>
                    </ul>
                    <div className="md:mx-0 md:ml-auto mt-4 pt-4 border-t border-solid border-gray-200 text-sm md:text-base md:border-0 md:mt-0 md:pt-0">
                        &copy; Adriana Fuchs, {new Date().getFullYear()}
                    </div>
                </div>
            </div>

        </>
    ) 
}

const NaviItem = ({href, children}) => {
    return (
        <li><a href={href} className="text-center font-medium">{children}</a></li>
    )
}