import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import ErrorPage from "./pages/Error";
import Disclaimer from "./pages/Disclaimer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/impressum",
    element: <Imprint />,
  },
  {
    path: "/datenschutz",
    element: <Disclaimer />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <div className="min-h-screen flex flex-col">
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </div>
  </React.StrictMode>
);
