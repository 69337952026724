// import bg from '../assets/design/hero-fox-2.jpg';
// import bg from '../assets/bilder/allgemein.jpg';
import { Helmet } from "react-helmet";
// import bgMd from '../assets/bilder/header-adriana-fuchs-104-lg.jpg';
// import bgLg from '../assets/bilder/header-adriana-fuchs-104-xl.jpg';
// import bgWebp from '../assets/bilder/header-adriana-fuchs-104-lg.webp';
// import bgLgWebp from '../assets/bilder/header-adriana-fuchs-104-xl.webp';
import { Image } from './Image';

export const Hero = () => {
    return (
        <section className="flex items-center justify-center w-full h-64 md:h-96 lg:h-hero-lg relative overflow-hidden bg-bg-2">
            <Image 
                src={"/header/header-adriana-fuchs-104-lg.jpg"}
                srcWebp="/header/header-adriana-fuchs-104-lg.webp"
                srcLg="/header/header-adriana-fuchs-104-xl.jpg"
                srcLgWebp="/header/header-adriana-fuchs-104-xl.webp"
                // src={bgMd}
                // srcWebp={bgWebp} 
                // srcLg={bgLg} 
                // srcLgWebp={bgLgWebp} 
                alt="Adriana Fuchs Kosmetik" 
                className="w-full h-64 md:h-96 lg:h-hero-lg absolute top-0 left-0 object-cover" 
                eager 
            />
        </section>
    )
}