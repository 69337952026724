import { Button } from "./Button"
import { Image } from "./Image"
import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { Icon } from '@mdi/react'
import { mdiClose } from '@mdi/js'
import classNames from 'classnames'

export const Teaser = ({image, imageWebp, imageLg, imageLgWebp, alt, headline, info, duration, price, dialog, dialogTitle, dialogDescription, children}) => {
    let [isOpen, setIsOpen] = useState(false)

    return (
        <>
        <div className={classNames("w-full h-auto flex flex-col bg-white rounded-xl overflow-hidden border border-solid border-gray-200", {"cursor-pointer": dialog})} onClick={() => setIsOpen(true)}>
            <div className="flex w-full aspect-4/3 overflow-hidden relative bg-bg-2">
                <Image 
                    src={image} 
                    srcWebp={imageWebp}
                    srcLg={imageLg}
                    srcLgWebp={imageLgWebp}
                    alt={alt || ""} 
                />
            </div>
            <div className="w-full flex flex-col p-4">
                <div className="flex mb-2 font-medium text-xl md:text-2xl">{headline}</div>
                {info && (
                    <div className="flex pb-2" dangerouslySetInnerHTML={{__html: info}}></div>
                )}
                {dialog && (
                    <div className="flex">
                        <Button className="text-base underline cursor-pointer hover:no-underline">
                            mehr Informationen
                        </Button>
                    </div>
                )}
            </div>
            {price && (
                <div className="mt-auto px-4 pb-2 flex flex-col">
                    {duration && (
                        <div className="flex pt-px">
                            <span className="text-sm text-gray-600">{duration}</span>
                        </div>
                    )}
                    <Button>
                        ab {price},- €
                    </Button>
                </div>
            )}
        </div>
        {dialog && (
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="w-full h-full fixed top-0 left-0 z-50 bg-black/50 p-6 grid place-items-center overflow-y-auto">
                <DialogPanel className="max-w-lg space-y-4 border bg-white p-4 sm:p-8 md:p-12 rounded-lg relative">
                    <DialogTitle className="font-bold">{dialogTitle}</DialogTitle>
                    <Description>{dialogDescription}</Description>
                    {children}
                    <div className="flex gap-4">
                    <button onClick={() => setIsOpen(false)}>
                        <Icon path={mdiClose} size={1} className="absolute top-4 right-4 cursor-pointer" />
                    </button>
                    </div>
                </DialogPanel>
            </Dialog>
        )}
        </>
    ) 
}