import React, { useState } from 'react';
import classNames from 'classnames';
import logo from '../assets/design/logo-adriana-fuchs-md.jpg';
import logoWebp from '../assets/design/logo-adriana-fuchs-md.webp';
import { Icon } from '@mdi/react';
import { mdiFacebook, mdiInstagram } from '@mdi/js';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <div className="w-full h-16 md:h-24 bg-white shadow-lg flex items-center justify-center z-50 px-4 fixed left-0 top-0">
                <div className="w-full max-w-screen-xl flex items-center justify-between">
                    
                    <a href="/" className="w-44 h-20 lg:w-60 lg:h-28 rounded shadow-logo mt-10 bg-white p-2 flex shrink-0">
                        <picture>
                            <source type="image/webp" media="(min-width: 0px)" srcSet={logoWebp} />
                            <source type="image/jpeg" media="(min-width: 0px)" srcSet={logo} />
                            <img src={logo} alt="Adriana Fuchs Kosmetik" title="Startseite" className="w-full h-full object-contain" />
                        </picture>
                    </a>

                    <div className="ml-auto mr-2 flex md:order-5 md:ml-8 gap-2">
                        <a href="https://www.instagram.com/Adriana.Fuchs/" title="zu Facebook" target="_blank" className="w-8 h-8 flex items-center justify-center">
                            <Icon path={mdiInstagram} size={1.1} />
                        </a>
                        <a href="https://www.facebook.com/Adriana.Fuchs.Kosmetik/" title="zu Facebook" target="_blank" className="w-8 h-8 flex items-center justify-center">
                            <Icon path={mdiFacebook} size={1.1} />
                        </a>
                    </div>

                    <div className="md:hidden">
                        <button onClick={() => setIsOpen(!isOpen)} className="w-8 h-8 flex items-center justify-center" title="Menu">
                            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>

                    <div className={classNames("w-full h-full hidden fixed left-0 top-0 bg-black/80 md:w-auto md:h-auto md:bg-transparent md:static md:flex ml-auto", {"!flex": isOpen})}>
                        {isOpen && (
                            <div className="w-16 h-16 flex items-center justify-center right-0 top-0 z-50 absolute cursor-pointer" onClick={handleClose}>
                                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        )}
                        <ul className="flex flex-col items-center justify-center mx-auto md:mx-0 md:flex-row gap-x-4 gap-y-10 md:gap-y-4 md:gap-x-5 lg:gap-x-10">
                            <NaviItem click={handleClose} href="/#willkommen">Willkommen</NaviItem>
                            <NaviItem click={handleClose} href="/#behandlungen">Behandlungen & Preise</NaviItem>
                            <NaviItem click={handleClose} href="/#ueber">Über mich</NaviItem>
                            <NaviItem click={handleClose} href="/#kontakt">Kontakt</NaviItem>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="w-full h-16 md:h-24" />
        </>
    ) 
}

const NaviItem = ({href, children, click}) => {
    return (
        <li><a className="p-t text-white text-2xl font-medium md:font-base md:text-lg md:text-black hover:underline" href={href} onClick={click}>{children}</a></li>
    )
}