export const Image = ({src, srcLg, srcWebp, srcLgWebp, alt, title, className, eager}) => {
    return (
        <picture>
            <source type="image/webp" media="(min-width: 800px)" srcSet={`${srcLgWebp}`} />
            <source type="image/webp" media="(min-width: 0px)" srcSet={`${srcWebp}`} />
            <source type="image/jpeg" media="(min-width: 800px)" srcSet={`${srcLg}`} />
            <source type="image/jpeg" media="(min-width: 0px)" srcSet={`${src}`} />
            <img src={`${src}`} alt={alt} title={title} loading={ eager ? "eager" : "lazy"} className={ className || "w-full h-full object-cover absolute left-0 top-0"} />
        </picture>
    )
}