import classNames from "classnames"

export const Section = ({id, children, classes, center, ...props}) => {
    return (
        <section className={classNames("flex flex-col items-center justify-center w-full px-5 py-12 md:py-14 lg:py-16 relative", classes)} {...props}>
            <div id={id} className="absolute -top-24" />
	        <div className={classNames("w-full max-w-screen-xl flex flex-col mx-auto", {"items-center justify-center": center})}>
                {children}
            </div>
        </section>
    ) 
}