import { useRouteError } from "react-router-dom";
import { Section } from "../components/Section";
import { Headline } from "../components/Headline";
import { Button } from "../components/Button";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Section className="my-20" center>
      <Headline as="h1">Oops!</Headline>
      <p>Entschuldigung, hier geht es leider nicht weiter.</p>

      {error.status === 404 && (
        <p>
          Die Seite, die du suchst, konnte nicht gefunden werden. Vielleicht
          hast du dich vertippt?
        </p>
      )}
      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
      <div className="mt-6">
        <Button as="a" href="/">
          zur Startseite
        </Button>
      </div>
    </Section>
  );
}