import { Hero } from '../components/Hero';
import { Section } from '../components/Section';
import { Headline } from '../components/Headline';
import { Teaser } from '../components/Teaser';
import adriana from '../assets/bilder/adriana-md.jpg';
import { Image } from '../components/Image';

import imgEye1 from '../assets/bilder/augen-1-md.jpg';
import imgEye1Webp from '../assets/bilder/augen-1-md.webp';
import imgEye1Lg from '../assets/bilder/augen-1-lg.jpg';
import imgEye1LgWebp from '../assets/bilder/augen-1-lg.webp';


import imgGeneral from '../assets/bilder/adriana-fuchs-228-r-lg.jpg';
import imgGeneralWebp from '../assets/bilder/adriana-fuchs-228-r-lg.webp';
import imgGeneralLg from '../assets/bilder/adriana-fuchs-228-r-lg.jpg';
import imgGeneralLgWebp from '../assets/bilder/adriana-fuchs-228-r-lg.webp';

import imgHydro from '../assets/bilder/hydro-md.jpg';
import imgHydroWebp from '../assets/bilder/hydro-md.webp';
import imgHydroLg from '../assets/bilder/hydro-lg.jpg';
import imgHydroLgWebp from '../assets/bilder/hydro-lg.webp';

import imgMeso from '../assets/bilder/meso-md.jpg';
import imgMesoWebp from '../assets/bilder/meso-md.webp';
import imgMesoLg from '../assets/bilder/meso-lg.jpg';
import imgMesoLgWebp from '../assets/bilder/meso-lg.webp';

import imgMikro from '../assets/bilder/mikro-84-md.jpg';
import imgMikroWebp from '../assets/bilder/mikro-84-md.webp';
import imgMikroLg from '../assets/bilder/mikro-84-lg.jpg';
import imgMikroLgWebp from '../assets/bilder/mikro-84-lg.webp';

import imgRadio from '../assets/bilder/radio-34-md.jpg';
import imgRadioWebp from '../assets/bilder/radio-34-md.webp';
import imgRadioLg from '../assets/bilder/radio-34-lg.jpg';
import imgRadioLgWebp from '../assets/bilder/radio-34-lg.webp';

import imgUltraschall from '../assets/bilder/ultraschall-12-md.jpg';
import imgUltraschallWebp from '../assets/bilder/ultraschall-12-md.webp';
import imgUltraschallLg from '../assets/bilder/ultraschall-12-lg.jpg';
import imgUltraschallLgWebp from '../assets/bilder/ultraschall-12-lg.webp';

import imgPermanent from '../assets/bilder/permanent-41-md.jpg';
import imgPermanentWebp from '../assets/bilder/permanent-41-md.webp';
import imgPermanentLg from '../assets/bilder/permanent-41-lg.jpg';
import imgPermanentLgWebp from '../assets/bilder/permanent-41-lg.webp';

import imgVerwoehn from '../assets/bilder/verwoehn-121-md.jpg';
import imgVerwoehnWebp from '../assets/bilder/verwoehn-121-md.webp';
import imgVerwoehnLg from '../assets/bilder/verwoehn-121-lg.jpg';
import imgVerwoehnLgWebp from '../assets/bilder/verwoehn-121-lg.webp';

import imgWimpern from '../assets/bilder/wimpern-97-md.jpg';
import imgWimpernWebp from '../assets/bilder/wimpern-97-md.webp';
import imgWimpernLg from '../assets/bilder/wimpern-97-lg.jpg';
import imgWimpernLgWebp from '../assets/bilder/wimpern-97-lg.webp';

import imgAdriana2 from '../assets/bilder/adriana-fuchs-95-r-md.jpg';
import imgAdriana2Webp from '../assets/bilder/adriana-fuchs-95-r-md.webp';
import imgAdriana2Lg from '../assets/bilder/adriana-fuchs-95-r-lg.jpg';
import imgAdriana2LgWebp from '../assets/bilder/adriana-fuchs-95-r-lg.webp';



function Home() {

    
    return (
        <>
            <Hero />

            <Section id="willkommen">
                <div className="grid grid-cols-12 md:grid-cols-12 gap-5 md:gap-10">
                    <div className="col-span-12 md:col-span-8">
                        <Headline as="h1">Willkommen im Kosmetikstudio Adriana Fuchs – schön, dass du hier bist!</Headline>
                        <p className="text-lg">
                            In meinem Studio biete ich dir ein umfangreiches Angebot an hochwertigen Behandlungen, die perfekt auf deine individuellen Bedürfnisse abgestimmt sind. Lass dich von mir verwöhnen und genieße deine Auszeit vom Alltag.
                            Ich freu mich darauf, dich bei mir begrüßen zu dürfen und dir zu helfen, Deine natürliche Schönheit zu entfalten. 
                            <br/>
                            Entdecke mein vielfältiges Angebot und meine exkusiven Produkte. 
                            <br/><br/>
                            Gönn dir das Besondere – denn Du hast es dir verdient.
                            <br/>
                            Deine Adriana Fuchs
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <div className="flex w-full aspect-4/3 rounded-xl overflow-hidden relative bg-bg-2">
                            <Image 
                                src={imgGeneral} 
                                srcWebp={imgGeneralWebp} 
                                srcLg={imgGeneralLg} 
                                srcLgWebp={imgGeneralLgWebp} 
                                alt="Adriana Fuchs Kosmetik"
                            />
                        </div>
                    </div>
                </div>
            </Section>

            <Section id="behandlungen" classes="bg-bg-2">
                <Headline as="h2">Behandlungen und Preise</Headline>
                {/* <p className="text-lg">
                    Hier finden Sie eine Auswahl meiner Behandlungen und Preise. Gerne berate ich Sie individuell und stelle Ihnen ein auf Ihre Bedürfnisse abgestimmtes Behandlungskonzept zusammen.
                </p> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-6 md:gap-y-10 mt-10">
                    <Teaser
                        image={imgRadio}
                        imageWebp={imgRadioWebp}
                        imageLg={imgRadioLg}
                        imageLgWebp={imgRadioLgWebp}
                        alt="Radiofrequenz-Needling"
                        headline="Radiofrequenz-Needling"
                        info="Regt schonend die Kollagen- und Elastinbildung und sorgt für ein jüngeres und frisches Hautbild."
                        duration="ca. 50 Minuten"
                        price="179"
                        dialog={true}
                        dialogTitle="Radiofrequenz-Needling"
                    >
                        Innovative Anti-Aging-Behandlungsmethode, die schonend für eine sichtbar jüngere und straffere Haut sorgt: <br/>
                        Durch die Kombination von Micronadeln und Radiofrequenz-Energie werden Wachstumsfaktoren in der tiefen Dermis stimuliert und die Bildung von Fibroblasten, Kollagen und Elastin angeregt. Dies führt zu einer deutlichen Verbesserung der Hautstruktur und glättet und strafft die Haut nachhaltig.<br/>
                        <br/>
                        <b>Vorteile der Behandlung:</b>
                        <ul>
                        <li>- hocheffektiv bereits nach erstmaliger Behandlung</li>
                        <li>- nach zwei bis sechs Behandlungen in kurzen Zeitabständen kann auf den Einsatz von Hyaluronsäure als Faltenfiller verzichtet werden</li>
                        <li>- keine Ausfallzeiten</li>
                        <li>- schmerzarm</li>
                        <li>- Preis ab 3 Anwendungen: 179,- Euro pro Anwendung</li>
                        </ul>
                    </Teaser>
                    <Teaser
                        image={imgMikro}
                        imageWebp={imgMikroWebp}
                        imageLg={imgMikroLg}
                        imageLgWebp={imgMikroLgWebp}
                        alt="Mikro Needling"
                        headline="Mikro Needling"
                        info="Frische und straffe Haut: Mit Hilfe eines Derma-Pens werden über feinste Nadeln Wirkstoffe in die Haut eingeschleust und die Selbstregeneration, Kollagen – und Zellbildung neu angeregt."
                        duration="ca. 60 Minuten"
                        price="129"
                    />
                    <Teaser
                        image={imgMeso}
                        imageWebp={imgMesoWebp}
                        imageLg={imgMesoLg}
                        imageLgWebp={imgMesoLgWebp}
                        alt="Mesotherapie"
                        headline="Mesotherapie"
                        info="Einbringung von Wirkstoffen in tiefere Hautschichten durch schmerzfreie elektrische Impulse."
                        duration="ca. 50 Minuten"
                        // info="Die Mesotherapie ist eine minimal-invasive Behandlung, bei der Wirkstoffe direkt in die Haut eingebracht werden. (ca. 60 Minuten)"
                        price="83"
                    />
                    <Teaser
                        image={imgUltraschall}
                        imageWebp={imgUltraschallWebp}
                        imageLg={imgUltraschallLg}
                        imageLgWebp={imgUltraschallLgWebp}
                        alt="Ultraschall"
                        headline="Ultraschall"
                        info="Behandlung mit Lifting-Gel sorgt für sofortige Faltenminderung und Anregung der Zellregeneration."
                        duration="ca. 60 Minuten"
                        price="72"
                    />
                    <Teaser
                        image={imgHydro}
                        imageWebp={imgHydroWebp}
                        imageLg={imgHydroLg}
                        imageLgWebp={imgHydroLgWebp}
                        alt="Hydro-Algen-Behandlung"
                        headline="Hydro-Algen-Behandlung"
                        info="Spirulina-Algen-Maske, die für Feuchtigkeitsbalance jeden Hauttyps sorgt, speziell auch bei Neurodermitis und Psoriasis."
                        duration="ca. 80 Minuten"
                        price="57"
                    />
                    <Teaser
                        image={imgVerwoehn}
                        imageWebp={imgVerwoehnWebp}
                        imageLg={imgVerwoehnLg}
                        imageLgWebp={imgVerwoehnLgWebp}
                        alt="Verwöhn-Behandlung"
                        headline="Verwöhn-Behandlung"
                        info="Hautreinigung, Korrektur der Augenbrauen, Auftragung von Wirkstoff-Konzentrat und pflegender Maske, entspannende Gesichts-, Hals- und Dekolletémassage, Abschlusspflege."
                        duration="ca. 80 Minuten"
                        price="51"
                    />
                    <Teaser
                        image={imgEye1}
                        imageWebp={imgEye1Webp}
                        imageLg={imgEye1Lg}
                        imageLgWebp={imgEye1LgWebp}
                        alt="Wimpernextension"
                        headline="Wimpernextension"
                        info="Verlängerung und Verdichtung der Wimpern mit Einzelwimpern oder 3D-Technik."
                        duration="ca. 60 Minuten"
                        price="57"
                        dialog={true}
                        dialogTitle="Wimpernextension"
                    >
                        <div className="w-full flex">Erstbehandlung <b className="ml-auto pl-2">ab 104,- Euro</b></div>
                        <div className="w-full flex">Auffüllen <b className="ml-auto pl-2">ab 57,- Euro</b></div>
                        <div></div>
                    </Teaser>
                    <Teaser
                        image={imgPermanent}
                        imageWebp={imgPermanentWebp}
                        imageLg={imgPermanentLg}
                        imageLgWebp={imgPermanentLgWebp}
                        alt="Permanent Make-Up"
                        headline="Permanent Make-Up"
                        info="Augenbrauen, Wimpernkranzverdichtung, Lidstrich."
                        duration="ca. 60 Minuten"
                        price="99"
                        dialog={true}
                        dialogTitle="Permanent Make-Up"
                    >
                        <div>
                            <b>Augenbrauen</b><br/>
                            - komplett 299,- Euro<br/>
                            <br/>
                            <b>Wimpernkranzverdichtung</b><br/>
                            - oben 149,- Euro<br/>
                            - unten  99,- Euro<br/>
                            <br/>
                            <b>Lidstrich</b><br/>
                            - oben 199,- Euro<br/>
                            - unten 149,- Euro<br/>
                            <br/>
                            <b>Jede Nachbehandlung innerhalb von 2 Monaten</b><br/>
                            - Augenbrauen 76,- Euro<br/>
                            - Lidstrich 59,- Euro<br/>
                        </div>
                    </Teaser>
                    <Teaser
                        image={imgWimpern}
                        imageWebp={imgWimpernWebp}
                        imageLg={imgWimpernLg}
                        imageLgWebp={imgWimpernLgWebp}
                        alt="Zusatzleistungen"
                        headline="Zusatzleistungen"
                        dialog={true}
                        dialogTitle="Zusatzleistungen"
                    >
                        <div>
                            <div className="w-full flex">Augenbrauenkorrektur <b className="ml-auto">6,- Euro</b></div>
                            <div className="w-full flex">Augenbrauen färben <b className="ml-auto">8,- Euro</b></div>
                            <div className="w-full flex">Wimpern färben <b className="ml-auto">10,- Euro</b></div>
                            <div className="w-full flex">Komplettpreis <b className="ml-auto">17,- Euro</b></div>
                            <div className="w-full flex">Wimpern Lifting <b className="ml-auto">49,- Euro</b></div>
                            <div className="my-4">
                                <b>Haarentfernung mit Wachs oder Faden</b>
                            </div>
                            <div className="w-full flex">Oberlippe <b className="ml-auto">10,- Euro</b></div>
                            <div className="w-full flex">Gesicht <b className="ml-auto">15,- Euro</b></div>
                            <div className="w-full flex">Achseln <b className="ml-auto">15,- Euro</b></div>
                        </div>
                    </Teaser>
                    {/* <Teaser
                        image={wimpern}
                        headline="Wimpernkranzverdichtung"
                        info="Durch feinste Linienzeichnung wird der Wimpernkranz optisch verdichtet und die Augen wirken größer und ausdrucksvoller."
                        price="149"
                    /> */}
                </div>
            </Section>

            <Section id="ueber">
                <Headline as="h2">Über mich</Headline>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                    <p className="text-lg">
                        Mit meinen Anti-Aging-Anwendungen biete ich dir optimale auf dein Hautbild abgestimmte Behandlungen. Als staatlich anerkannte Kosmetikerin mit einer medizinischen Ausbildung arbeite ich leidenschaftlich gerne mit Menschen und freue mich, dich und dein Aussehen zum Strahlen zu bringen.
                    </p>
                    <p className="text-lg">
                        Als berufstätige Mutter zweier Kinder kenne ich die Herausforderungen des Alltags und weiß, wie wichtig es ist, sich und seiner Haut Aufmerksamkeit zu schenken. Effektive Anti-Aging-Behandlungen sind ideal, um deine Haut lebendiger und frischer aussehen zu lassen, damit du dich in deiner Haut wohlfühlst. Lass uns gemeinsam deine Haut verwöhnen!
                    </p>
                </div>
            </Section>

        </>
    );
}

export default Home;
